/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import Head from 'next/head';
import type { JSX } from 'react';
import {
  FAVICON_SIZES,
  APPLE_TOUCH_ICON_SIZES,
  buildFaviconHref,
  buildAppleTouchIconHref,
  getTwitterMetadata,
} from './metadata';

interface FavIconsProps {
  children?: JSX.Element | JSX.Element[];
}

/**
 * Renders FavIcons in differnt sizes
 */
export function FavIcons({ children }: FavIconsProps) {
  return (
    <Head>
      {FAVICON_SIZES.map((size) => (
        <link
          key={size}
          rel="icon"
          type="image/png"
          href={buildFaviconHref(size)}
          sizes={`${size}x${size}`}
        />
      ))}
      {children}
    </Head>
  );
}

/**
 * Renders Apple touch icons in different sizes
 */
export function AppleTouchIcons() {
  return (
    <Head>
      {APPLE_TOUCH_ICON_SIZES.map((size) => (
        <link
          key={size}
          rel="apple-touch-icon"
          type="image/png"
          href={buildAppleTouchIconHref(size)}
          sizes={`${size}x${size}`}
        />
      ))}
    </Head>
  );
}

interface TwitterMetaProps {
  title?: string;
  image?: string;
  description?: string;
}

/**
 * Renders Twitter related meta tags
 */
export function TwitterMeta({ title, description, image }: TwitterMetaProps) {
  const metadata = getTwitterMetadata({ title, description, image });
  return (
    <Head>
      {Object.entries(metadata).map(([key, value]) => (
        <meta name={`twitter:${key}`} content={value} key={key} />
      ))}
    </Head>
  );
}
